2<template>
  <div class="pageContol listWrap templateList classCheckDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="projectSource == '30'">
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toList">班级审核</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级审核详情</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toList">班级审批</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级审批详情</a>
        </span>
      </div>
      <div class="framePage-body ovy-a">
        <h3 style="padding-bottom:20px;">基本信息</h3>
        <el-form ref="ruleForm" label-width="200px" class="el_form">
          <el-form-item label="班级名称:" class="form-inline">
            <p>{{ detail.projectName }}</p>
          </el-form-item>
          <el-form-item label="班级编码:" class="form-inline">
            <p>{{ detail.projectCode }}</p>
          </el-form-item>
          <el-form-item label="行政区划:" class="form-inline">
            <p>{{ detail.areaNamePath || "无" }}</p>
          </el-form-item>
          <el-form-item label="有效日期:" class="form-inline">
            <p v-if="detail.startDate && detail.endDate">
              {{ detail.startDate | momentDate }}至{{
              detail.endDate | momentDate
              }}
            </p>
            <p v-else>无</p>
          </el-form-item>
          <el-form-item label="报名截止时间:" class="form-inline">
            <p v-if="detail.signEndDate">{{ detail.signEndDate | momentDate }}</p>
            <p v-else>无</p>
          </el-form-item>
          <el-form-item label="负责人:" class="form-inline">
            <p>{{ detail.projectUser || "" }}</p>
          </el-form-item>
          <el-form-item label="负责人电话:" class="form-inline">
            <p>{{ detail.projectUserPhone || "" }}</p>
          </el-form-item>
          <el-form-item label="培训类型:" class="form-inline">
            <p>{{ detail.Trainingtype || "无" }}</p>
          </el-form-item>
          <el-form-item label="培训人数:" class="form-inline">
            <p>{{ detail.projectPeople || "无" }}</p>
          </el-form-item>
          <el-form-item label="培训地点:" class="form-inline">
            <p>{{ detail.projectAddress || "无" }}</p>
          </el-form-item>
          <el-form-item label="期次:" class="form-inline">
            <p>{{ detail.projectPc || "无" }}</p>
          </el-form-item>
          <el-form-item label="考试机构:" class="form-inline">
            <p>{{ detail.projectPaperComp || "无" }}</p>
          </el-form-item>
          <el-form-item label="考试时间:" class="form-inline">
            <p v-if="detail.projectPaperDate">{{ detail.projectPaperDate | momentDate }}</p>
            <p v-else>无</p>
          </el-form-item>
          <el-form-item label="人群类型:" class="form-inline" v-show="detail.projectCrowd">
            <p v-if="detail.projectCrowd">{{ $setDictionary("CROWDTYPE", detail.projectCrowd) }}</p>
          </el-form-item>
          <el-form-item label="备案号:" class="form-inline">
                  <p>
                    {{
                      detail.projectRecordno
                    }}
                  </p>
                </el-form-item>
                <el-form-item label="备案课时数:" class="form-inline">
                  <p>
                    {{
                      detail.recordClasshours
                    }}
                  </p>
                </el-form-item>
          <el-form-item label="申请表图片: " class="form-inline">
                  <div
                    v-if="detail.list.length"
                    style="display: flex; flex-direction: column"
                  >
                    <span
                      style="color: #409eff"
                      v-for="(item, index) in detail.list"
                      :key="index"
                    >
                      {{ item.fileName }}
                      <a
                        @click="lookExl(item)"
                        style="color: #409eff; margin: 0 15px"
                        >预览</a
                      >
                    </span>
                  </div>
                  <div v-else>
                    <p>无</p>
                  </div>
                </el-form-item>
          <el-form-item label="附件列表:" prop="fileName" class="form-inline">
          <div style="display: flex; flex-direction: column" v-if="detail.eduProjectFileList">
            <span
              style="color: #409eff"
              v-for="(item, index) in detail.eduProjectFileList"
              :key="index"
            >
              {{ item.fileStyleValue }}
              <a @click="downloadFile(item)" v-if="item.downloadUrl" style="color: #5bb5ff;margin:0 15px">下载</a>
            </span>
          </div>
          <div v-else>无</div>
        </el-form-item>
          
        </el-form>
        <div class="el-div"></div>
        <h3 style="padding-bottom:20px;">基本设置</h3>
        <div class="baseSet">
          <span style="padding:0 15px">允许学员查看学时证明:</span>
          <span>{{ detail.projectClassHourCertificate == true ? "是" : "否" }}</span>
        </div>
        <div class="el-div"></div>
        <h3 style="padding-bottom:20px;">课程信息</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="classDetail"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
          >
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
            <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" />
            <el-table-column label="课程来源" align="center" show-overflow-tooltip prop="compName" />
            <el-table-column label="课程总学时" align="center" show-overflow-tooltip prop="lessonNum" />
            <el-table-column
              label="要求完成学时"
              align="center"
              show-overflow-tooltip
              prop="lessonNumDemand"
            />
            <el-table-column
              label="必修总学时"
              align="center"
              show-overflow-tooltip
              prop="lessonNumMust"
            />
            <el-table-column label="选修总学时" align="center" show-overflow-tooltip prop="electiveNum" />
            <el-table-column label="课后考试" align="center" show-overflow-tooltip prop="paperId">
              <template slot-scope="scope">
                <span>{{ scope.row.paperId ? "有" : "无" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button
                  style="padding:0 5px"
                  type="text"
                  size="mini"
                  @click="see(scope.row.projectCourseId, scope.row.courseId)"
                >详情</el-button>
                <el-button
                  v-if="scope.row.paperId"
                  style="padding:0 5px"
                  type="text"
                  size="mini"
                  @click="showPaper(scope.row.paperId)"
                >结业试卷预览</el-button>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
        <div class="el-div"></div>
        <h3 style="margin:30px 0 20px;">学员信息</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="studentList"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="计划培训人数"
              align="center"
              :show-overflow-tooltip="true"
              prop="planPeople"
            />
            <el-table-column
              label="已导入学员人数"
              align="center"
              :show-overflow-tooltip="true"
              prop="actualPeople"
            />
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  style="padding:0 5px"
                  type="text"
                  size="mini"
                  @click="seeStudentInfo(scope.row)"
                >详情</el-button>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
        <div class="el-div"></div>
        <h3 style="margin:30px 0 20px;">审批记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="checkDetail"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
          >
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
            <el-table-column label="操作日期" align="center" show-overflow-tooltip prop="createTime">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime | moment }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人" align="center" show-overflow-tooltip prop="fullname" />
            <el-table-column label="操作内容" align="center" show-overflow-tooltip prop="auditState">
              <template slot-scope="scope">
                <span>{{ getAuditName(scope.row.auditState) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="驳回原因" align="center" show-overflow-tooltip prop="auditReason">
              <template slot-scope="scope">
                <span
                  style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline"
                >{{ scope.row.auditReason ? scope.row.auditReason : "--" }}</span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
        <div class="el-div"></div>
        <div v-if="projectSource == '30'">
          <h3 style="margin:30px 0 20px;">监管点名</h3>
          <div class="operationControl flexcb">
            <div class="studentnum">
              <span>未分组学员：{{ ungroupUserCount }}</span>
            </div>
            <div class="df ci-full">
              <el-button class="bgc-bv" round @click="doGroup()">学员分组</el-button>
              <el-button class="bgc-bv" round @click="fastCall">快速点名设置</el-button>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="groupList"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
              >
                <!-- <el-table-column label="序号" align="center" type="index" :index="indexMethod" /> -->
                <el-table-column label="组别" align="center" show-overflow-tooltip prop="groupName" />
                <el-table-column label="人数" align="center" show-overflow-tooltip prop="userNum" />
                <el-table-column label="点名状态" align="center" show-overflow-tooltip prop="mobile">
                  <template slot-scope="scope">
                    {{
                    scope.row.dotNum > 0 ? "已设置" : "未设置"
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="点名次数" align="center" show-overflow-tooltip prop="dotNum" />
                <el-table-column label="操作" align="center" width="400px">
                  <div slot-scope="scope" class="flexcc">
                    <div style="margin-right:10px">
                      <el-button
                        style="padding:0px 15px"
                        type="text"
                        size="mini"
                        @click="doSet(scope.row)"
                      >点名设置</el-button>
                    </div>
                  </div>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
        </div>

        <div class="btn-boxall" v-if="projectSource == '30'">
          <!-- 石家庄 三个按钮-->
          <el-button
            v-if="
              projectState == '25' ||
                projectState == '30' ||
                projectState == '50'
            "
            @click="checkNo"
            class="bgc-bv"
          >驳 回</el-button>
          <el-button
            class="bgc-bv"
            v-if="projectState == '25' || projectState == '45'"
            @click="checkYes"
          >通 过</el-button>
          <el-button @click="goback" class="bgc-bv">取 消</el-button>
        </div>
        <div class="btn-boxall" v-if="projectSource == '20'">
          <!-- 长春  只允许看-->
          <el-button @click="goback" class="bgc-bv">取 消</el-button>
        </div>
        <div class="btn-boxall" v-if="projectSource == '10'">
          <!-- 普通  只允许驳回-->
          <el-button
            v-if="
              projectState == '20' ||
                projectState == '30' ||
                projectState == '50' ||  auditState == '25'
            "
            @click="checkNo"
            class="bgc-bv"
          >驳 回</el-button>
          <el-button  v-if="
              auditState == '25'
            "
            @click="checkYes" class="bgc-bv">通 过</el-button>
          <el-button @click="goback" class="bgc-bv">取 消</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="驳回原因" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-input
          type="textarea"
          placeholder="请输入审核理由"
          v-model="auditReason"
          maxlength="50"
          show-word-limit
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <PaperShow ref="PaperShow" :modal="false" />
    <el-dialog title="快速点名设置" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="groupForm" style="width:100%">
        <el-form-item label="分组数量：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.groupNum"></el-input>
            <span style="margin-left:5px">组</span>
          </div>
        </el-form-item>
        <el-form-item label="点名次数：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.rollCallNum"></el-input>
            <span style="margin-left:5px">次</span>
          </div>
        </el-form-item>
        <el-form-item label="时间间隔：" label-width="100px" prop="groupNum">
          <div class="df">
            <el-input v-model="groupForm.timeInterval"></el-input>
            <span style="margin-left:5px">分</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doAjax">确 定</el-button>
      </div>
    </el-dialog>
       <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      :modal="false"
      width="50%"
      top="2%"
      center
      v-dialogDrag
    >
      <div class="ovy-a" style="height:600px">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import PaperShow from "@/views/resourse/popup/PaperShow";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "SupervisionEnd/classCheckDetail",
  components: {
    PaperShow
  },
  mixins: [List],
  data() {
    return {
      //点名
      dialogFormVisible: false,
      ungroupUserCount: 0,
      groupForm: {
        groupNum: "",
        rollCallNum: "",
        timeInterval: ""
      },
      groupList: [], //点名分组
      rollNumber: 0, //点名次数
      rollNumberNew: 0, //点名次数新值
      rollNumberEdit: true,
      projectState: this.$route.query.projectState, //班级状态
      projectSource: this.$route.query.projectSource, //是否外部班级状态30-石家庄
      auditState: this.$route.query.auditState, //是否外部班级状态30-石家庄
      dialogVisible: false,
      auditReason: "",
      classDetail: [],
      detail: {},
      checkDetail: [],
      projectId: "",
      studentList: [],
     previewLoding1: false,
      ImgSrc: "",
    };
  },
  created() {
    this.projectId = this.$route.query.id;
    //  this.projectId="1173"
    this.$post("/biz/project/query", { projectId: this.$route.query.id }).then(
      re => {
        this.detail = {
          ...re.data,
           list:re.data.list || [],
        eduProjectFileList:re.data.eduProjectFileList || []
          }
        let name1 = "";
        let name2 = "";
        let name3 = "";
        let name4 = "";
        let name5 = "";
        if (re.data.codeBo.trainTypeNamePath) {
          name1 = re.data.codeBo.trainTypeNamePath;
        }
        if (re.data.codeBo.postName) {
          name2 = "/" + re.data.codeBo.postName;
        }
        if (re.data.codeBo.industryNamePath) {
          name3 = "/" + re.data.codeBo.industryNamePath;
        }
        if (re.data.codeBo.occupationNamePath) {
          name4 = "/" + re.data.codeBo.occupationNamePath;
        }
        if (re.data.codeBo.trainLevelName) {
          name5 = "/" + re.data.codeBo.trainLevelName;
        }
        let name = name1 + name2 + name3 + name4 + name5;
        this.detail.Trainingtype = name.replace(/,/g, "/");
        this.rollNumber = this.detail.randomRollCall;
      }
    );
    this.$post("/biz/projectCourse/queryProjectCourseDetail", {
      projectId: this.$route.query.id
    }).then(re => {
      this.classDetail = re.data;
    });
    this.$post("/os/auditLog/queryLogInfoByType", {
      auditObjectId: this.$route.query.id,
      pageNum: 0,
      pageSize: 0
    }).then(re => {
      this.checkDetail = re.data;
    });
    this.getRollList();
    this.getStudentList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() {
    //  this.getTableHeight();
  },
  methods: {
    getAuditName(value) {
      let AuditNameList = [
        {
          value: "10",
          label: "班级创建",
          labelSJZ: "班级创建"
        },
        {
          value: "20",
          label: "提交审核",
          labelSJZ: "提交审核"
        },
        {
          value: "25",
          label: "运营审核通过",
          labelSJZ: "运营审核通过"
        },
        {
          value: "30",
          label: "审核通过",
          labelSJZ: "监管审核通过"
        },
        {
          value: "40",
          label: "审核驳回",
          labelSJZ: "运营审核驳回"
        },
        {
          value: "45",
          label: "",
          labelSJZ: "运营审核驳回"
        }
      ];
      for (let i in AuditNameList) {
        if (AuditNameList[i].value == value) {
          if (this.projectSource == "30") {
            return AuditNameList[i].labelSJZ;
          } else {
            return AuditNameList[i].label;
          }
        }
      }
    },
    getRollList() {
      //点名列表
      this.$post("/biz/roll/group/projectRollCall", {
        projectId: this.projectId,
        rollType: "20"
      }).then(res => {
        if (res.status == 0) {
          const data = res.data || [];
          this.groupList = data.groupList;
          this.ungroupUserCount = data.ungroupUserCount;
        }
      });
    },
    // 学员分组
    doGroup() {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/studentGroup",
        query: {
          projectId: this.projectId,
          oldData: JSON.stringify({
            id: this.$route.query.id,
            projectState: this.$route.query.projectState,
            projectSource: this.$route.query.projectSource
          })
        }
      });
    },
    // 快速点名
    fastCall() {
      this.dialogFormVisible = true;
    },
    doAjax() {
      const reg = /^[1-9]?\d*$/;
      if (this.groupForm.groupNum && !reg.test(this.groupForm.groupNum)) {
        this.$message({
          type: "warning",
          message: "分组数应为正整数"
        });
        return;
      }
      if (this.groupForm.rollCallNum && !reg.test(this.groupForm.rollCallNum)) {
        this.$message({
          type: "warning",
          message: "点名次数应为正整数"
        });
        return;
      }
      if (
        this.groupForm.timeInterval &&
        !reg.test(this.groupForm.timeInterval)
      ) {
        this.$message({
          type: "warning",
          message: "时间间隔应为正整数"
        });
        return;
      }
      const params = {
        ...this.groupForm,
        projectId: this.projectId,
        projectSource: 30
      };
      this.$post("/biz/roll/group/quickSetup", params).then(res => {
        this.dialogFormVisible = false;
        this.$message({
          type: "success",
          message: "操作成功"
        });
        this.getRollList();
      });
    },
    //点名设置
    doSet(row) {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/RollCallSetDetail",
        query: {
          groupId: row.groupId,
          projectId: row.projectId,
          userNum: row.userNum,
          groupName: row.groupName,
          oldData: JSON.stringify({
            id: this.$route.query.id,
            projectState: this.$route.query.projectState,
            projectSource: this.$route.query.projectSource
          })
        }
      });
    },
    showPaper(paperId) {
      this.$refs.PaperShow.showPopUp(paperId); // 打开弹窗预览试题
    },
    toList() {
      if (this.projectSource == "30") {
        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/classReview"
        });
      } else {
        this.$router.back();
      }
    },
    // 下载附件列表
    downloadFile(item){
      const link = document.createElement("a");
      link.href = item.downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    checkNo() {
      this.dialogVisible = true;
    },
    checkYes() {
      //石家庄的班级，点击通过后，跳转到点名预览页面，页面点击确定，调确定接口，跳转到列表页面
      //普通班直接调通过接口
      if (this.projectSource == "30") {
        if (this.groupList.length == 0) {
          this.$confirm("该班级未设置监管点名，是否继续？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning"
          })
            .then(() => {
              this.$post("/run/project/modifyAuditPassState", {
                resourceId: this.projectId,
                auditState: "30"
              }).then(re => {
                if (re.status == 0) {
                  this.$message({
                    message: "操作成功",
                    type: "success"
                  });
                  this.toList();
                }
              });
            })
            .catch(() => {});
        } else {
          this.$router.push({
            path: "/web/SupervisionEnd/sjzRegulator/classRecord",
            query: {
              projectId: this.projectId,
              stu: "view",
              stuD: "detail",
              stus:'details'
            }
          });
        }
      } else {
        this.$post("/run/project/modifyAuditPassState", {
          resourceId: this.projectId,
          auditState: "30"
        }).then(re => {
          if (re.status == 0) {
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.toList();
          }
        });
      }
    },
    goback() {
      this.toList();
    },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning"
        });
        return false;
      } else {
        if (this.auditReason.length < 5) {
          this.$message({
            message: "驳回理由不少于五个字",
            type: "warning"
          });
          return false;
        }
      }
      this.$post("/run/project/modifyAuditRejectState", {
        resourceId: this.projectId,
        auditReason: this.auditReason,
        auditState: this.projectSource == "30" ? "45" : "40"
      }).then(re => {
        if (re.status == 0) {
          this.auditReason = "";
          this.dialogVisible = false;
          this.toList();
        }
      });
    },
    /* 课程详情 */
    see(projectCourseId, courseId) {
      this.$router.push({
        path: this.detail.projectSource!='30'? "/web/SupervisionEnd/classJgCourse":'/web/classdetailtabs',
        query: {
          projectId: this.projectId,
          projectCourseId,
          courseId,
          stu: "view",
          projectSource: this.detail.projectSource
        }
      });
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    getStudentList() {
      this.$post("/run/project/projectStudentInfo", {
        projectId: this.projectId
      })
        .then(ret => {
          if (ret.status == "0") {
            let obj = {};
            if (ret.data.planPeople) {
              obj.planPeople = ret.data.planPeople;
            }
            if (ret.data.actualPeople) {
              obj.actualPeople = ret.data.actualPeople;
            }
            this.studentList.push(obj);
          }
        })
        .catch(err => {
          return;
        });
    },
    seeStudentInfo(row) {
      this.$router.push({
        path: "/web/AuditManagement/studentDetail",
        query: {
          projectId: this.projectId,
          projectName: this.detail.projectName,
          projectCode: this.detail.projectCode,
          stu: "zfjg"
        }
      });
    },
     //预览
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
  }
};
</script>
<style lang="less">
.classCheckDetail {
  // th{
  //   background-color: #ffffff !important;
  //   color:#000 !important;
  //   border-top: 1px solid rgb(92, 107, 232);
  // }
  .btn-box {
    text-align: center;
    margin-top: 30px;
  }
  .el_form {
    .form-inline {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }

  .baseSet {
    span {
      margin: 20px 0 20px;
    }
  }
  .framePage-body {
    width: 100%;
    overflow-y: auto;
  }
  .el-div {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    border-bottom: 1px solid #dcdfe6;
  }
}
</style>

